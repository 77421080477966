/* eslint-disable no-unused-vars */
import Swiper from 'swiper/bundle';

/* Carousel */
var carousel_swiper = new Swiper(".carousel-swiper", {
  slidesPerView: 1.2,
  spaceBetween: 20,
  loop: true,
  navigation: {
    nextEl: '.swiper-next',
    prevEl: '.swiper-prev',
  },
  breakpoints: {
    768: {
      spaceBetween: 40,
      slidesPerView: 2.4,
    },
  },
  a11y: {
    slideRole: '',
  },
});

/* Slider section values */
var values_swiper = new Swiper('.values-swiper', {
  autoplay: true,
  loop: true,
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
    clickable: true,
  },
});

/* eslint-enable no-unused-vars */