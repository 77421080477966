// import external dependencies
import jquery from 'jquery';
global.$ = global.jQuery = jquery;

import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/offcanvas';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/collapse';

import './modules/sticky-header';
import './modules/anchor-menu';
import './modules/sliders';
